.none {
	background: transparent !important;
	background-color: transparent !important;
}

* {
	font-family: "Graphik Compact", sans-serif;
	font-weight: normal;
	padding: 0;
	margin: 0;
}

html, body, #root {
	height: 100%;
	background-color: #FFFFFF;
	min-width: 1100px;
}

.cursor-pointer {
	cursor: pointer;
}

.mrg-left-10 {
	margin-left: 10px;
}

.mrg-right-10 {
	margin-right: 10px;
}

.mrg-top-10 {
	margin-top: 10px;
}

.mrg-top-5 {
	margin-top: 5px;
}

.mrg-bt-5 {
	margin-bottom: 5px;
}

.mrg-bt-10 {
	margin-bottom: 10px;
}

.padding-lr-24 {
	padding-left: 24px;
	padding-right: 24px;
}

.pac-container:after {
	background-image: none !important;
	height: 0;
}

.pac-container {
	width: 371px !important;
	position: relative !important;
}

.underline {
	text-decoration: underline;
}

.Entities .ant-select-selection {
	width: 100%;
}

.modal-facet-groups .ant-checkbox-wrapper {
	height: 25px;
	display: flex;
	align-items: flex-end;
}

.comments-button {
	border: none;
}

.button-like-link {
	display: flex;
	justify-content: flex-end;
	box-shadow: none;
	background-color: #fff;
	border: none;
	text-decoration: underline;
	color: var(--color-main);
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 10px;
	font-family: "Graphik Compact", sans-serif;
	font-weight: normal;
}

.button-like-link:hover {
	box-shadow: none;
	border: none;
}

.bold-button {
	font-size: 18px;
}

.btn-required-link {
	color: var(--color-main) !important;
}

.unactive .ant-select-selection {
	border: none;
	width: 100%;
}

.unactive .ant-select-arrow {
	display: none;
}

.active .ant-select-selection {
	width: 100%;
}

.text-wrap {
	width: 45px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.text-wrap-long {
	width: 95px;
}

.tooltip {
	position: relative;
}

.tooltip:hover:after {
	background: #333;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 5px;
	bottom: 26px;
	color: #fff;
	padding: 5px 15px;
	position: absolute;
	z-index: 98;
	width: 100px;
	min-width: 100px;
}

.left-menu-layout {
	padding: 0 20px 20px;
	margin-top: 20px;
}

.left-menu-layout .ant-layout-content {
	min-height: calc(100vh) !important;
}

/**
customize divider ant component for analyst mode
 */
.axis-divider {
	border: 0;
	background-color: white;
	border-radius: 0;
	margin-bottom: 20px;
}

.axis-divider > .ant-collapse-item {
	border-bottom: 0 !important;
}

.axis-divider > .ant-collapse-item > .ant-collapse-content {
	border-top: 0 !important;
}

.axis-divider > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding: 0 0 0 20px;
}

.axis-divider .ant-divider-horizontal.ant-divider-with-text-left:before {
	width: 0;
}

.axis-divider > .ant-collapse-item > .ant-collapse-header {
	padding-top: 0;
	padding-bottom: 0;
}

.jsonEditor .ant-tabs-bar {
	height: 0;
	visibility: hidden;
}

body {
	color: #231e20 !important;
}

.main-layout {
	padding: 0 20px 20px;
	margin-top: 20px;
}

.main-layout .ant-layout-content {
	min-height: calc(100vh - 85px) !important;
}

.nav-item-style {
	font-size: 16px;
	font-weight: normal !important;
}

.editor-width {
	width: 100%;
}

.preview-part {
	max-height: calc(100vh - 245px);
	overflow-y: auto;
}

.ant-select-selection-selected-value {
	margin-left: -3px;
}

.assigned-entities-select {
	width: 100%;
	color: #000;
	font-size: 14px;
}

.scroll-block {
	overflow-y: auto;
}

.react-data-grid .ant-select-open, .react-data-grid .ant-select-focused, .react-data-grid .ant-select-selection {
	border-color:white !important;
	background-color: #ffffff00 !important;
	font-size: 14px !important;
}

.react-data-grid .ant-input, .react-data-grid .ant-select-selection-selected-value {
	font-size: 14px !important;
}

.react-data-grid .input-sm {
	line-height: 20px;
	color: var(--color-secondary-dark);
	padding-left: 4px;
	height: 34px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-height: 30px;
	font-family: "Graphik Compact", sans-serif;
	font-weight: normal;
	font-size: 12px;
	border: 1px solid #cccccc;
	border-radius: 3px;
	border-right: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	width: 100%;
}

.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Canvas {
	overflow-y: hidden !important;
}

.RDGSelect {
	height: 35px;
	width: 100%;
}

.RDGSelect .ant-select-selection-selected-value {
	font-size: 11pt;
}

td {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: break-word;
}

.FlexRow {
	display: flex;
}
