.spin {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: 0 auto;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f8f8f8;
}

.form {
  background-color: white;
  padding: 20px 40px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px 1px #d4d4d4;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.formColumn {
  margin-bottom: 10px;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 5px;
}

.actions button {
  margin-left: 10px;
}