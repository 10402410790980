.FacetAssignTable__RDG .react-grid-Header .react-grid-HeaderRow .react-grid-HeaderCell .form-group input[class*="form-control input-sm"] {
  max-height: 30px;
  border: 1px solid #cccccc;
  color: #555;
  border-radius: 3px;
  line-height: 20px;
  padding: 4px;
  width: 100%;
}

#CONTROLS_ROW_ID > * {
  z-index: 1 !important;
}