.page {
  background-color: var(--color-secondary-light);
}

.container {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 500px;
  min-height: 100vh;
  padding: 25px 25px 25px 50px;
  background-color: var(--color-white);
}

.loader {
  width: 150px;
}
