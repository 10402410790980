.Facet .ant-collapse-content-box {
  padding-left: 0 !important;
}
.Facet .ant-collapse-item {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.Facet .Facet-Content {
  padding-left: 47px !important;
}

.Count {
  margin-left: 10px;
  font-size: 22px;
  font-weight: bold;
}

.publish-btn {
  margin-right: 10px;
}