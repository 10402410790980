.SignUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f8f8f8;
}
.SignUp .form {
  background-color: white;
  padding: 20px 40px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px 1px #d4d4d4;
  width: 400px;
  display: flex;
  flex-direction: column;
}
.SignUp .form .form-column {
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}
.SignUp .form .form-column:last-child {
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 5px;
}
