.container {
  display: flex;
  flex-direction: column;
}

.container p {
  margin-bottom: 25px;
}

.container p:last-child {
  margin-bottom: 5px;
}
