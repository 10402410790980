.info-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	width: 18px;
	height: 18px;
	background-color: #f4f3f7;
}

.facet-type-field {
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	font-size: 16px;
}

.facet-type-field .field__actions {
	display: flex;
	padding: 10px;
}

.facet-type-field .field__actions button {
	margin-right: 10px;
}

.facet-type-field .extra-field {
	margin-left: 6px;
	color: var(--color-main-dark);
}

.facet-type-field .label-text {
	font-size: 18px;
}

.facet-type-field .label-row {
	font-size: 18px;
	display: flex;
	justify-content: space-between;
}

.facet-type-field .column {
	display: flex;
	flex-direction: column;
}

.facet-type-field .link-item {
	background-color: white;
	border: 1px solid #d6d6d6;
	border-radius: 4px;
	padding: 10px;
	margin-bottom: 10px;
}

.facet-type-field .link-item .link-item-toolbar {
	margin-bottom: 5px;
	display: flex;
	justify-content: space-between;
}

.facet-type-field > div > div > .ant-collapse-header {
	margin-left: -40px;
	color: black !important;
	font-size: 18px;
}

.facet-type-field > div > div > .ant-collapse-header .anticon .anticon-right .arrow {
	color: black;
}

.facet-type-field > div > div > .ant-collapse-header .anticon {
	margin-top: 2px !important;
	font-size: 11pt !important;
}

.facet-type-field .ant-collapse-content-box {
	padding-left: 0 !important;
}

.description {
	width: 100%;
	font-style: italic;
	color: #4d4d4d;
	font-size: 17px;
}

.ant-calendar-range-picker-input {
	word-wrap: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.dropdown-zindex {
	z-index: 1 !important;
}

.ButtonAddItem {
	cursor: pointer;
}

.ButtonAddContainer {
	display: flex;
}