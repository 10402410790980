@value colors: "./colorValues.css";
@value business, security, culture, governance, environment, economy, actorBackgroundDark, plotBackgroundDark, contextBackgroundDark, teal from colors;

.business {
  border-color: business;
}

.security {
  border-color: security;
}

.culture {
  border-color: culture;
}

.governance {
  border-color: governance;
}

.environment {
  border-color: environment;
}

.economy {
  border-color: economy;
}

.actor {
  border-color: actorBackgroundDark;
}

.plot {
  border-color: plotBackgroundDark;
}

.context {
  border-color: contextBackgroundDark;
}

.black {
  border-color: #444444;
}

.gray {
  border-color: #bbbbbb;
}

.lightGray {
  border-color: #dedede;
}

.white {
  border-color: white;
}

.teal {
  border-color: teal;
}
