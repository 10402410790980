.main {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--color-secondary-dark);
}

.name {
  white-space: nowrap;
  line-height: 32px;
  cursor: pointer;
  color: var(--color-secondary-dark);
}

.name:hover {
  color: var(--color-main);
}


.icon {
  margin: 2px 4px 0 10px;
  width: 12px;
  height: 12px;
  fill: var(--color-secondary);
}

.facets {
  display: flex;
  flex-direction: column;
  width: 100%
}

.type {
  color: var(--color-secondary);
}

.relationship {
  color: var(--color-secondary);
}

.subtype {
  color: var(--color-secondary);
}

.keywords {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.keyword {
  padding: 3px 8px;
  margin: 5px 0 5px 10px;
  box-sizing: border-box;
  border-radius: 50px;
  background-color: var(--color-secondary-light);
  color: var(--color-secondary-dark);
  font-size: 13px;
  line-height: 17px;
}
