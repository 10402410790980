.container {
  display: flex;
  flex-direction: column;
  min-height: 662px;
}

.imageContainer {
  position: relative;
  height: 326px;
  width: 100%;
}

.image {
  width: 100%;
  height: 326px;
  object-fit: cover;
}

.favicon {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 10px 33px 100px;
  overflow-x: auto;
}

.title {
  margin-top: 20px;
  color: var(--color-secondary-dark);
}

.date {
  margin-top: 2px;
  color: var(--color-secondary-dark);
}

.event {
  color: var(--color-secondary-dark);
  margin-bottom: 12px;
}

.brief {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: var(--color-secondary-dark);
}

.briefLink {
  color: var(--color-secondary-dark);
  text-decoration: none;
}

.briefLink:hover {
  color: var(--color-main);
}

.briefIcon {
  width: 12px;
  height: 12px;
  fill: var(--color-secondary);
  margin-right: 11px;
}

.entity {
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  padding: 6px 19px;
  border-radius: 10px;
  background-color: var(--color-white);
  color: var(--color-secondary-dark);
  align-items: center;
}

.name {
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  color: var(--color-secondary-dark);
}

.name:hover {
  color: var(--color-main);
}

.topic {
  white-space: nowrap;
  margin-left: 10px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  padding: 3px 8px;
  margin: 5px 0 5px 10px;
  box-sizing: border-box;
  border-radius: 50px;
  background-color: var(--color-secondary-very-light);
  color: var(--color-secondary-dark);
  font-size: 13px;
  line-height: 17px;
}

.section {
  margin-top: 30px;
}

.article, .article * {
  font-size: 16px;
  line-height: 23.7px;
  font-weight: 400;
  font-family: 'PT Sans', sans-serif;
  color: var(--color-secondary-dark);
}

.article strong {
  font-weight: bold;
}

.article span {
  padding: 3px 8px;
  box-sizing: border-box;
  border-radius: 50px;
  background-color: var(--color-secondary);
  color: var(--color-white);
  font-size: 14px;
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.link {
  display: flex;
}

.textLink {
  color: var(--color-secondary-dark);
  text-decoration: none;
}

.textLink p:hover {
  color: var(--color-main);
}

.translate {
  fill: var(--color-secondary-dark);
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.body {
  margin-top: 40px;
}

.source {
  margin-top: 20px;
}
