.wrapper {
  display: flex;
  width: fit-content;
}

.container {
  cursor: pointer;
  display: flex;
  width: fit-content;
}

.text {
  color: var(--color-secondary-dark);
  font-size: 13px;
  margin-left: 5px;
}

.icon {
  min-width: 13px;
  width: 13px;
  height: 18px;
  fill: var(--color-secondary-dark);
}

.container:hover .text {
  color: var(--color-main);
}
.container:hover .icon {
  fill: var(--color-main);
}

.remove {
  margin-left: 5px;
}
.remove svg {
  fill: var(--color-secondary-dark);
  height: 9px;
  width: 9px;
}

.modal :global(.ant-modal-body) {
  padding: 55px 30px;
  margin: 0;
  display: flex;
}

.modal :global(.ant-modal-content) {
  box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

/* modal */
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.button {
  margin-top: 30px;
}

.select {
  width: 100%;
  margin-right: 150px;
}
