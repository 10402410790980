.relationshipEntityWrapper {
	display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.relationshipEntityWrapper > div {
  width: 30%;
}

.error{
  border: 1px solid red;
}

.itemList {
  display: flex;
  flex-wrap: wrap;
}


.item {
  margin-bottom: 5px;
  display: flex;
  max-width: 300px;
  padding: 8px;
}

.itemText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.itemText:hover {
  color: var(--color-main);
}

.buttonRemove {
  margin-left: 12px;
}

.buttonRemove svg {
  height: 9px;
  width: 9px;
  fill: var(--color-secondary-dark);
}