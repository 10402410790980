.main {
  display: flex;
  --width-creation-form: 500px;
}

.main[data-content='manual'] {
  justify-content: center;
  background-color: var(--color-secondary-very-light);
}

.articles {
  background-color: var(--color-secondary-very-light);
  max-width: calc(100% - var(--width-creation-form));
  overflow-x: hidden;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 60px
}

.creation {
  width: var(--width-creation-form);
  padding: 25px 25px 25px 50px;
  flex-shrink: 0;
  background-color: var(--color-white);
}

.main[data-content='manual'] .creation {
  padding: 25px;
}

.creationTitle {
  margin-bottom: 60px;
}
