.select-country-wrapper {
	display: flex;
}

.select-countries{
	width: 100%;
	margin-right: 10px;
}

.select-briefs{
	margin-top: 30px;
}

.select-primary-country{
	width: 30%;
	margin-right: 10px;
}

.roles {
	margin-top: 30px;
}

.education {
	margin-top: 30px;
}

.entity-editor {
	font-weight: 500;
	display: inline-block;
	flex-direction: column;
	background-color: white;
	min-height: calc(100vh);
	width: 100%;
	flex: 1;
	padding: 10px 40px 40px 45px;
}

.entity-editor > div > div > .ant-collapse-content {
	border-bottom: 1px solid #f1f0f3ff;
}

.entity-editor > div > div > .ant-collapse-header {
	padding-left: 20px !important;
	border-bottom: 1px solid black;
}

.entity-editor > div > div > .ant-collapse-header .anticon {
	left: 0 !important;
	margin-top: 2px;
	font-size: 16px !important;
}

.entity-editor .ant-collapse-borderless > .ant-collapse-item {
	border: none;
}

.entity-editor .ant-collapse-content > .ant-collapse-content-box {
	padding: 0;
}

.entity-editor .facet {
	padding-top: 10px;
	padding-bottom: 5px;
	border-bottom: 1px solid #e8e7eb;
}

.entity-editor .facet > div > div > .ant-collapse-header {
	padding-left: 20px;
}

.entity-editor .facet > div > div > .ant-collapse-header .anticon {
	left: 0 !important;
	font-size: 15px;
}

.entity-editor .facet .ant-collapse-header {
	border-bottom: none;
	color: var(--color-secondary-dark);
}

.entity-editor .facet .ant-collapse-content > .ant-collapse-content-box {
	padding: 0 0 0 46px;
}

.entity-editor .ant-affix {
	z-index: 4000;
}

.entity-editor .ant-btn {
	border-color: var(--color-secondary-dark);
	border-width: 1px;
	color: var(--color-secondary-dark);
}

.entity-editor > div > div > div > div > .ant-collapse-header {
	padding-left: 20px !important;
	border-bottom: 1px solid black;
}

.entity-editor > div > div > div > div > .ant-collapse-header .anticon {
	left: 0 !important;
	margin-top: 2px;
	font-size: 16px !important;
}

.modal-save-settings {
	min-width: 720px;
}

.modal-save-settings .modal-save-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.modal-save-settings .modal-save-container .logs-title {
	font-size: 16px;
}

.modal-save-settings .modal-save-container .mass-assign {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.modal-save-settings .modal-save-container .mass-assign > span {
	font-size: 20px;
	margin-bottom: 10px;
}

.modal-save-settings .modal-save-container .mass-assign .nodata {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	width: 100%;
	text-align: center;
	padding-top: 15px;
	text-decoration: underline;
}

.modal-save-settings .modal-save-container .mass-assign .filters {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.modal-save-settings .modal-save-container .mass-assign .list {
	position: relative;
	display: block;
	height: 330px;
	overflow-y: auto;
}

.modal-save-settings .modal-save-container .mass-assign .list .item {
	height: auto;
	display: flex;
	flex-direction: column;
}

.modal-save-settings .modal-save-container .mass-assign .list .item .section {
	line-height: 1.2;
	border-bottom: 1px solid #6d6d6d;
	font-size: 16px;
	margin-top: 10px;
}

.modal-save-settings .modal-save-container .mass-assign .list .item .line {
	align-items: center;
	line-height: 1.4;
	display: flex;
	flex-direction: row;
	padding: 5px 0;
	border-bottom: 1px solid #bbbbbb;
}

.modal-save-settings .modal-save-container .mass-assign .list .item .line .level, .modal-save-settings .modal-save-container .mass-assign .list .item .line .facet, .modal-save-settings .modal-save-container .mass-assign .list .item .line .stage, .modal-save-settings .modal-save-container .mass-assign .list .item .line .assigned {
	flex: 1;
}

.modal-save-settings .modal-save-container .mass-assign .list .item .line .facet {
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
}

.modal-save-settings .modal-save-container .mass-assign .mass-assign-controls {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #bbbbbb;
	border-bottom: 1px solid #bbbbbb;
}

.modal-save-settings .modal-save-container .mass-assign .mass-assign-controls > div {
	max-width: 150px;
	margin-left: 10px;
}

.modal-save-settings .modal-save-container .ant-btn {
	border-color: var(--color-main-dark);
	border-width: 1px;
	color: var(--color-main-dark);
}

.facet-logs, .entity-logs {
	display: inline-block;
	flex-direction: column;
}

.facet-logs .ant-collapse-borderless > .ant-collapse-item, .entity-logs .ant-collapse-borderless > .ant-collapse-item {
	border: none;
}

.facet-logs .button-show-hide, .entity-logs .button-show-hide {
	padding-left: 100px;
}

.facet-logs .button-show-hide .button-like-text, .entity-logs .button-show-hide .button-like-text {
	color: var(--color-main-dark);
	cursor: pointer;
	font-size: 18px;
}

.facet-logs .log-list, .entity-logs .log-list {
	display: flex;
	flex-direction: column;
}

.facet-logs .log-item, .entity-logs .log-item {
	font-size: 18px;
	color: var(--color-black);
	display: flex;
}

.facet-logs .log-item span:nth-child(1), .entity-logs .log-item span:nth-child(1) {
	width: 100px;
}

.facet-logs .log-item span:nth-child(2), .entity-logs .log-item span:nth-child(2) {
	flex: 1;
	font-style: italic;
	color: #4d4d4d;
}

.facet-logs .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box, .entity-logs .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding-top: 0;
}

.facet-logs .ant-collapse-content > .ant-collapse-content-box, .entity-logs .ant-collapse-content > .ant-collapse-content-box {
	padding: 0 !important;
}

.facet-logs .ant-collapse > .ant-collapse-item > .ant-collapse-header, .entity-logs .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	display: none;
}

.sidebar-item {
	font-weight: 300;
}

.sidebar-item * {
	letter-spacing: 0.2px;
}

.sidebar-item span {
	display: inline-block;
	min-width: 14px;
}

.unnamed {
	color: gray;
}

.link-to-links {
	padding-left: 40px;
	color: black;
	font-weight: 400 !important;
	font-size: 24px !important;
}

.analystEditorArchived {
	padding-top: 26px;
	max-height: 200px;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: white;
	border-bottom: 1px solid rgba(207, 207, 207, 0.76);
	width: 100%;
}

.analystEditorArchived .ant-row-flex {
	align-items: center;
	display: -webkit-box;
	margin-bottom: 8px;
}

.analystEditorArchivedDesc {
	margin-bottom: 16px;
	padding-left: 34px;
}

.analystEditorArchivedItem {
	padding: 2px 10px 2px;
	margin-top: -3px;
	font-family: "Graphik Compact", sans-serif;
	font-weight: normal;
	font-size: 14px;
	background-color: var(--color-secondary);
	width: calc(100% - 35px);
}

.analystEditorArchivedItem span {
	font-family: "Graphik Compact", sans-serif;
	font-weight: normal;
	font-size: 14px;
}

.ant-popover-buttons .ant-btn-primary {
	color: white !important;
}

.ant-popover-buttons .ant-btn-primary:hover {
	color: var(--color-main-dark) !important;
}

.EditorLoading {
	height: 100vh;
	width: 100%;
	background-color: white;
}

.ant-affix .EntityEditor_Header {
	border-bottom: 2px solid #dddedf;
}