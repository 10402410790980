.title {
  color: var(--color-secondary-dark);
}

.subtitle {
  color: var(--color-secondary-dark);
  font-weight: 400;
  font-size: 16px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card {
  padding: 10px 24px 10px 18px;
  border: 0.549518px solid var(--color-briefs-light-hex);
  box-sizing: border-box;
  box-shadow: 0px 8px 4.39614px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  user-select: none;
}

.save {
  color: white;
  border: none;
  border-radius: 3px;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  box-shadow: 0 2px #00000004;
  cursor: pointer;
  line-height: 1.5;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.icon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  fill: var(--color-secondary-dark);
  cursor: pointer;
}

.icons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.entry {
  width: 33%;
}

.bigEntry {
  width: 67%;
}

.modal:global(.ant-modal) {
  top: calc(50% - 145px);
}

.content {
  text-align: center;
  padding: 36px 75px;
  color: var(--color-black);
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.buttonText {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  padding: 0 25px;
  margin-right: 25px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
}

.loader {
  width: 50px;
}

.select {
  min-width: 250px;
}

.dates {
  width: 50%;
  display: flex;
  margin-top: 20px;
}

.source {
  width: 50%;
}