.container {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.input {
  margin-top: 30px;
}

.dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;
}


.location {
  margin-top: 11px;
}

.titleContainer {
  padding-bottom: 34px;
}

.title {
  color: var(--color-secondary-dark);
}

.button {
  margin-top: 50px;
}