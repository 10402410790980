.ModalCreateEntity__Table td:nth-child(1) {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
}
.ModalCreateEntity__Table td:nth-child(2) {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
}
.ModalCreateEntity__Table td:nth-child(3) {
  min-width: 100px;
}
.ModalCreateEntity__Table td:nth-child(4) {
  min-width: 50px;
}

.ModalCreateEntity__Button {
  margin-top: 5px;
}