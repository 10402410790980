
.CrossEntityFacetEditor .FormContainer label {
  display: inline-block;
  font-weight: 500;
  margin-bottom: 4px;
}
.CrossEntityFacetEditor .FormContainer .Field_CompressionSet .ant-form-item-children-icon {
  display: none;
}
.CrossEntityFacetEditor .FormContainer .FormContainer-Col {
  max-width: 500px;
}
.CrossEntityFacetEditor .FormContainer .FormContainer-Col .ant-col {
  padding-bottom: 12px;
}
.CrossEntityFacetEditor .FormContainer .FormContainer-Col .ant-select {
  display: block;
}