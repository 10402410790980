.container {
  padding: 36px 75px 68px 62px;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.pills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pill {
  margin: 5px 14px;
}

.actions {
  display: flex;
  margin-left: 20px;
  flex-shrink: 0;
}

.buttonSecondary {
  font-weight: normal;
  margin-right: 56px;
}

.title {
  margin-top: 40px;
  color: var(--color-secondary-dark);
}

.subtitle {
  margin-bottom: 10px;
}

.titleSeparator {
  height: 1px;
  background-color: var(--color-secondary-light);
  border: none;
}

.separator {
  margin: 30px 0;
  height: 1px;
  background-color: var(--color-secondary-light);
  border: none;
}

.regulation {
  display: flex;
  align-items: center;
}

.input {
  flex-shrink: 0;
  margin: 0 30px 0 50px;
}

.end {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.loading {
  width: 50px;
  height: 50px;
}

.alert {
  margin: 20px;
}
