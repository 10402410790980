.save {
  background-color: #8f59ff;
  color: white;
  border: none;
  border-radius: 3px;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  box-shadow: 0 2px #00000004;
  cursor: pointer;
  line-height: 1.5;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.save:disabled {
  opacity: .5;
}

div.tag {
  border-color: #EDEDED;
  border-radius: 3px;
  width: 150px;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  font-family: Helvetica;
  font-weight: bold;
}

.tag[data-status="ok"] {
  background-color: white;
}

.tag[data-status="unsaved"] {
  background-color: #ededed;
}

.tag[data-status="error"] {
  background-color: #f23c734d;
}