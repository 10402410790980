.tableRow {
  display: flex;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 15px;
  color: var(--color-secondary-dark);
}

.tableRow:hover {
  background-color: rgba(0, 0, 0, 0.01);
}

.cell {
  height: 34px;
  display: flex;
  align-items: center;
}

.titleLink {
  color: var(--color-secondary-dark);
  user-select: none;
}

.titleLink:hover,
.titleLink:active {
  color: var(--color-main);
}

.cell p,
.cell a {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  padding-right: 15px;
}

.tableRow .cell:nth-child(1) {
  width: 42%;
  line-height: 30px;
}

.tableRow .cell:nth-child(2),
.tableRow .cell:nth-child(3) {
  width: 11%;
}
.tableRow .cell:nth-child(4) {
  width: 16%;
}
.tableRow .cell:nth-child(5) {
  width: 17%;
}
.tableRow .cell:nth-child(6) {
  width: 3%;
  justify-content: flex-end;
}

.buttonTrash {
  cursor: pointer;
  font-size: 0;
}

.buttonTrash svg {
  width: 24px;
  height: 28px;
}

.tableHeader {
  padding-top: 45px;
  padding-bottom: 8px;
}
.tableHeader:hover {
  background-color: unset;
}

.separator {
  height: 1px;
  background-color: var(--color-light-gray);
  border: none;
}
