.ContentLayout.main-layout,
.ContentLayout.LayoutEditor {
  margin-left: var(--width-admin-sidemenu);
  flex-direction: column;
}
.ContentLayout.collapse {
  margin-left: var(--width-admin-sidemenu-collapsed);
}

.LayoutEditor {
	padding: 0;
	margin: 0;
}

.LayoutEditor .ant-layout-content {
	min-height: calc(100vh) !important;
	margin-top: 0 !important;
}

.FullScreenLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
