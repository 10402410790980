.Container {
  width: 500px;
}

.sparkline {
  width: 100%;
  height: 100%;
}

.sparkline svg {
  overflow: visible;
}

.content {
  display: flex;
}

.left,
.right {
  flex: 0 0 auto;
}

.left {
  width: 45%;
  padding-right: 10px;
}

.left.italic {
  font-style: italic;
}

.right {
  width: 55%;
}

.statGoalGroup {
  display: flex;
  justify-content: space-between;
}

.futureStat,
.futureGoal {
  width: 50%;
  flex: 0 0 auto;
}

.futureGoal {
  display: flex;
  justify-content: flex-end;
}

.textTag {
  padding-top: 3px;
  padding-bottom: 2px;
  line-height: 1;
  display: inline-block;
  flex: 0 0 auto;
}

.textTag,
.yearStatStat {
  white-space: nowrap;
}

.goalTag {
  background-color: #fbb03b;
  padding-left: 3px;
  padding-right: 3px;
}

.difference {
  position: relative;
  top: -1px;
  text-align: center;
  white-space: nowrap;
}

.goal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.differencePositive {
  color: #00994e;
}

.differenceNegative {
  color: #ba3a3f;
}

.sparklineStat {
  display: flex;
  border-left: 1px solid #ddd;
  padding-bottom: 10px;
}

.sparklineStatLeft {
  width: 45%;
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 auto;
}

.sparklineStatRight {
  flex: 1 1 auto;
}
