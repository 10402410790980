.EditorSidebarWrapper {
	width: 265px;
}

.EditorSidebarWrapper .EditorSidebar {
	font-family: "Graphik Compact", sans-serif;
	font-weight: 100;
	background-color: #f4f3f7;
	width: 265px;
	min-width: 265px;
	height: 100%;
	position: fixed;
	z-index: 1;
	overflow-y: hidden;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__Container {
	padding-top: 17px;
	padding-left: 26px;
	padding-right: 26px;
	height: 100px;
	position: relative;
	z-index: 1;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__Pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__Pagination .ant-pagination-item-link {
	background-color: inherit;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__EntityList {
	padding-left: 14px;
	padding-right: 0;
	max-height: calc(100vh - 150px);
	height: calc(100vh - 150px);
	overflow: auto;
	overflow-x: hidden;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity {
	margin-bottom: 25px;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .class__text, 
.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .EditorSidebar__Name, 
.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .EditorSidebar__Topics, 
.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .EditorSidebar__Type {
	font-size: 24px;
	padding-left: 28px;
	padding-right: 24px;
	text-overflow: ellipsis;
	overflow: hidden;
	word-wrap: break-word;
	white-space: nowrap;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .EditorSidebar__Name {
	font-weight: 700;
	line-height: 24px;
	margin: 10px 0;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .EditorSidebar__Topics {
	font-weight: 300;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .EditorSidebar__Type {
	font-weight: 300;
	margin-bottom: 25px;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu {
	background: inherit;
	padding-top: 10px;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-submenu-title {
	line-height: 30px;
	height: 30px;
	margin: 0;
	color: #231f20;
	font-family: "Graphik Compact", sans-serif;
	font-weight: 400;
	font-size: 22px;
	padding-left: 26px !important;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item {
	background: inherit;
	border: none;
	color: var(--color-secondary-dark);
	font-family: "Graphik Compact", sans-serif;
	font-size: 21px;
	font-weight: 300;
	height: 30px;
	letter-spacing: -1px;
	line-height: 30px;
	margin: 0;
	padding: 0;
	padding-left: 26px !important;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item .sidebar-item {
	display: flex;
	align-items: center;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item .sidebar-item .EditorSidebar__MenuItem {
	max-width: 140px;
	letter-spacing: -1px;
	text-overflow: ellipsis;
	overflow: hidden;
	word-wrap: break-word;
	white-space: nowrap;
	margin-right: 5px;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item .sidebar-item .iconDiamond {
	width: 12px;
	height: 12px;
	margin-right: 15px;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item-selected, .EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item-active {
	background: inherit;
	border: none;
}

.EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item-selected:after, .EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item-selected:before, .EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item-active:after, .EditorSidebarWrapper .EditorSidebar .EditorSidebar__AssignedEntity .ant-menu .ant-menu-sub .ant-menu-item-active:before {
	border: none;
}