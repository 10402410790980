.select-option-avatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.select-option-avatar .short-name {
  flex: 10;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-option-avatar .short-name .short-name-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}
.select-option-avatar .avatar {
  flex: 1;
  display: flex;
  align-items: center;
  color: white;
}
.select-option-avatar .avatar .avatar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: white;
  margin-right: 10px;
}