.main {
  width: 200px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
}

.item {
  padding: 5px 5px 0 5px;
  margin: 2px 2px 0 0;
  background-color: #f4f4f4;
}

.text { 
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
}

.noBorder {
  border: 'none';
  background-color: 'inherit';
}

.content {
  height: 250px;
  overflow-y: auto;
}