.Statistic_Ceft-Pannel > div > div > .ant-collapse-header {
  margin-left: -40px;
  color: black !important;
  font-size: 18px;
}
.Statistic_Ceft-Pannel > div > div > .ant-collapse-header .anticon {
  color: black !important;
  width: 30px;
}
.Statistic_Ceft-Pannel > div > div > .ant-collapse-header .anticon svg {
  width: 14.66px;
  height: 14.66px;
  margin-left: -20px;
}