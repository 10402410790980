
.top {
  margin-top: 20px;
}

.bottom {
  margin-bottom: 20px;
}

.role {
  text-transform: capitalize;
}