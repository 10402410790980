.container {
  display: flex;
  height: 100vh;
  padding: 10px 20px;
  flex-direction: column;
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.input {
  width: 50%;
  margin-bottom: 10px;
}

.button {
  width: 100px;
  margin-bottom: 10px;
}

.section {
  margin-top: 40px;
}