.extraLarge {
	font-size: 30.8px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.extraLarge {
		font-size: 22px;
	}
}

.large {
	font-size: 22.4px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.large {
		font-size: 16px;
	}
}

.medium {
	font-size: 19.6px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.medium {
		font-size: 14px;
	}
}

.small {
	font-size: 16.8px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.small {
		font-size: 12px;
	}
}

.extraSmall {
	font-size: 14px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.extraSmall {
		font-size: 10px;
	}
}