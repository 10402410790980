@value colors: "./colorValues.css";
@value business, security, culture, governance, environment, economy, actorBackgroundDark, plotBackgroundDark, contextBackgroundDark, teal, black, gray, lightGray from colors;

.business {
  color: business;
}

.security {
  color: security;
}

.culture {
  color: culture;
}

.governance,
.government {
  color: governance;
}

.environment {
  color: environment;
}

.economy,
.yellow {
  color: economy;
}

.actor {
  color: actorBackgroundDark;
}

.plot {
  color: plotBackgroundDark;
}

.context {
  color: contextBackgroundDark;
}

.black {
  color: black;
}

.gray {
  color: gray;
}

.lightGray {
  color: lightGray;
}

.white {
  color: white;
}

.teal {
  color: teal;
}

.blue {
  color: #1e74fa;
}

.inherit {
  color: inherit;
}
