.row {
  width: 400px;
}

.col {
  font-size: 14px;
  width: 190px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

.select {
  width: 100%;
}
