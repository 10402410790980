@value colors: "./colorValues.css";
@value business, businessDark, security, securityDark, culture, cultureDark, governance, governanceDark, environment, environmentDark, economy, economyDark from colors;

.business {
  background-image: linear-gradient(90deg, business 0%, businessDark 100%);
}

.security {
  background-image: linear-gradient(90deg, securityDark 0%, securityDark 100%);
}

.culture {
  background-image: linear-gradient(90deg, culture 0%, cultureDark 100%);
}

.governance,
.government {
  background-image: linear-gradient(90deg, governance 0%, governanceDark 100%);
}

.environment {
  background-image: linear-gradient(
    90deg,
    environment 0%,
    environmentDark 100%
  );
}

.economy {
  background-image: linear-gradient(90deg, economy 0%, economyDark 100%);
}
