.container {
  display: flex;
  flex-direction: column;
}

.container p {
  margin-bottom: 5px;
}

.container a {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  color: var(--color-main);
}
.container a:hover {
  color: var(--color-main-light);
}
