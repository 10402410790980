.container {
  padding: 20px 75px 68px 62px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.loadingIndicator {
  display: inline-flex;
  margin-left: 30px;
}

.spinner {
  width: 20px;
  height: 20px;
}

.loading {
  font-size: 12px;
  color: var(--color-secondary);
  margin: 0 0 0 8px;
  display: inline-block;
  line-height: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  margin-top: 10px;
  color: var(--color-secondary-dark);
  margin-bottom: 1px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.clearFilter {
  margin-right: 12px;
  font-size: 14px;
}

.titleSeparator {
  height: 1px;
  background-color: var(--color-gray);
  border: none;
}

.filters {
  margin-top: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter {
  flex-basis: calc(20% - 25px);
}

.end {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.modal:global(.ant-modal) {
  top: calc(50% - 145px);
}

.content {
  text-align: center;
  padding: 36px 75px;
  color: var(--color-black);
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 55px;
}

.buttonText {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  padding: 0 25px;
  margin-right: 25px;

}
