.Link {
  margin-bottom: 0px;
  font-size: 18px;
}
.Label {
  color: lightgray;
}

.type, .subType, .briefType, .topics {
  display: inline;
  margin-right: 4px;
  color: #b3b3b3 !important;
}
