.main {
  padding-top: 120px;
  margin: 0 auto 40px;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  text-align: center;
}

.subtitle {
  margin-bottom: 2rem;
  font-weight: normal;
}

.content {
  border: 1px solid var(--color-error);
  padding: 20px 60px;
  background-color: rgb(241, 206, 209);
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.error {
  display: block;
  width: 100%;
  color: var(--color-error);
  font-weight: bold;
  font-size: 12px;
}

.stack {
  padding-left: 20px;
  white-space: pre-wrap;
  color: var(--color-error);
  font-size: 10px;
  margin-bottom: 0;
}

.button {
  background-color: transparent;
  padding: 12px 32px;
  border: 1px solid var(--color-main-dark);
  border-radius: 4px;
  color: var(--color-main-dark);
  cursor: pointer;
}

.button:hover {
  color: white;
  background-color: var(--color-main-dark);
  transition: all .5s;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.link {
  background-color: transparent;
  padding: 12px 32px;
  border: 1px solid var(--color-main-dark);
  border-radius: 4px;
  color: var(--color-main-dark);
  cursor: pointer;
  display: inline-block;
}

.link:hover {
  color: white;
  background-color: var(--color-main-dark);
  transition: all .5s;
}