.modal {
  width: calc(100% - 40px);
  max-width: 500px;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
}

.content {
  text-align: center;
  padding: 36px 75px;
  color: var(--color-black);
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 55px;
}

.confirm {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  padding: 0 25px;
  margin-right: 25px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.loader {
  width: 30px;
  height: 30px;
}