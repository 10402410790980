.container {
  padding: 20px 40px 68px 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.loadingIndicator {
  display: inline-flex;
  margin-left: 30px;
}

.spinner {
  width: 20px;
  height: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: var(--color-secondary-dark);
  margin-bottom: 1px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.buttonNew {
  padding: 8px 12px;
  font-size: 18px;
}

.titleSeparator {
  margin-top: 10px;
  height: 1px;
  background-color: var(--color-gray);
  border: none;
}

.filters {
  margin: 18px 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.filter {
  margin-right: 15px;
  flex-basis: calc(30% - 25px);
}

.columnActions {
  width: 100px;
}

.end {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.loading {
  font-size: 12px;
  color: var(--color-secondary);
  margin: 0 0 0 8px;
  display: inline-block;
  line-height: 20px;
}

.table-row-new {
  background-color: #E7E7E7;
}

.table-row-healthy {

}

.table-row-warning {
  background-color: #FCEBD9;
}

.table-row-danger {
  background-color: #FDE2E2;
}

.link {
  cursor: pointer;
  color: var(--color-black);
  text-decoration: underline;
}

.link:hover {
  color: var(--color-main);
}

.icon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  fill: var(--color-black);
  cursor: pointer;
}

.icon:hover {
  fill: var(--color-main);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}