@value sizes: "./sizeValues.css";
@value fontExtraLarge, fontLarge, fontMedium, fontSmall from sizes;

.text {
  line-height: 1.25em;
}

.text > p:first-child {
  margin-top: 0;
}

.text > p:last-child {
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.italic {
  font-family: 'FreightDispProSemibold';
  font-style: italic;
  line-height: 1.15em !important;
}

.inline {
  display: inline;
}

.inlineBlock {
  display: inline-block;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.centered {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}
