.modal {
  width: calc(100% - 40px);
  max-width: 500px;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
}

.content {
  padding: 16px;
  color: var(--color-black);
}

.title {
  margin-top: 10px;
  color: var(--color-secondary-dark);
  margin-bottom: 10px;
}

.input {
  margin-top: 30px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.confirm {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  padding: 0 25px;
  margin-right: 25px;
}