.modal {
  width: calc(100% - 40px);
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
}

.title {
  margin-bottom: 25px;
}

.input {
  margin-top: 30px;
}

.submit {
  margin-top: 30px;
  padding: 8px 12px;
  font-size: 18px;
}

.item {
  margin-bottom: 5px;
  display: flex;
  max-width: 300px;
  padding: 8px;
}

.itemText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.itemText:hover {
  color: var(--color-main);
}

.buttonRemove {
  margin-left: 12px;
}

.buttonRemove svg {
  height: 9px;
  width: 9px;
  fill: var(--color-secondary-dark);
}

.inputContainer {
  position: relative;
  padding-top: 30px;
}

.input input {
  padding-right: 30px;
}

.inputClose {
  position: absolute;
  top: 22px;
  right: 12px;
}

.inputClose svg {
  width: 12px;
  height: 12px;
  fill: var(--color-secondary-dark);
}

.itemList {
  display: flex;
  flex-wrap: wrap;
}
