.modal:global(.ant-modal){
  top: unset;
}

.modal :global(.ant-modal-body) {
  padding: 0;
  margin: 0;
  display: flex;
}

.modal :global(.ant-modal-content) {
  box-shadow: unset;
}

.controls {
  position: sticky;
  margin-right: -170px;
  top: 326px;
  width: 170px;
  height: 100%;
}

.content {
  width: 100%;
  background-color: var(--color-secondary-very-light);
}
