.main {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
}

.category {
  display: flex;
  flex: 2;
  flex-direction: column;
  padding-right: 10;
}

.value {
  display: flex;
  flex: 4;
  flex-direction: column;
  padding-right: 10;
}

.actions {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.add {
  width: auto;
  margin-top: 20;
}