.section {
  width: 100%;
  font-family: 'PT Sans', sans-serif;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
}

.title {
  color: var(--color-secondary-dark);
  margin: 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-left: 10px;
}

.icon {
  width: 16px;
  height: 16px;
  fill: var(--color-secondary);
}
