.flex {
  display: flex;
}

.inlineFlex {
  display: inline-flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.alignCenter {
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.inline {
  display: inline;
}

.inlineBlock {
  display: inline-block;
}
