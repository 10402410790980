.modal-create-user {
  display: flex;
  flex-direction: column;
}
.modal-create-user .custom-row {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.modal-create-user .custom-row:last-child {
  margin-left: 12px;
  justify-content: flex-start;
}
.modal-create-user .custom-row:last-child span {
  margin-right: 12px;
}