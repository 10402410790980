.modal {
  width: calc(100% - 40px);
  max-width: 500px;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
}

.title {
  margin-bottom: 25px;
}

.input {
  margin-top: 30px;
}

.submit {
  margin-top: 30px;
  padding: 8px 12px;
  font-size: 18px;
}
