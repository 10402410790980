.header {
  margin-bottom: 32px;
}
.header button {
  margin: 0 6px;
}
.header button:first-child {
  margin-left: 0;
}
.header button:last-child {
  margin-right: 0;
}