.container {
  position: relative;
  width: 100%;
}

.empty {
  color: var(--color-secondary);
  font-size: .9rem;
  font-style: italic;
}

.source {
  margin-bottom: 5px;
  display: flex;
  max-width: 300px;
}

.sourceText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.sourceText:hover {
  color: var(--color-main);
}

.source a {
  max-width: inherit;
  color: var(--color-secondary-dark);
  text-decoration: none;
}

.buttonRemove {
  margin-left: 12px;
}

.buttonRemove svg {
  height: 9px;
  width: 9px;
  fill: var(--color-secondary-dark);
}

.inputContainer {
  position: relative;
  padding-top: 0px;
}

.input input {
  padding-right: 30px;
}

.inputClose {
  position: absolute;
  top: 22px;
  right: 12px;
}

.inputClose svg {
  width: 12px;
  height: 12px;
  fill: var(--color-secondary-dark);
}
