.toast {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  max-width: 500px;
}