.container {
  position: relative;
  width: 100%;
}

.source {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  max-width: 300px;
}

.sourceText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.sourceText:hover {
  color: var(--color-main);
}

.source a {
  max-width: inherit;
  color: var(--color-secondary-dark);
  text-decoration: none;
}

.buttonRemove {
  margin-left: 12px;
}

.buttonRemove svg {
  height: 9px;
  width: 9px;
  fill: var(--color-secondary-dark);
}

.buttonUpload {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
  padding: 10px 13px;
  margin-bottom: 10px;
}

.buttonUpload svg {
  width: 11px;
  height: 13px;
}

.buttonUpload:hover {
  border-color: var(--color-main);
}

.buttonUpload:hover svg {
  fill: var(--color-main);
}

.buttonUpload:hover path {
  fill: var(--color-main);
}

.buttonUpload:hover p {
  color: var(--color-main);
}
