.main {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 120px;
  height: 120px;
}