.ant_customs__facets_section > .ant-collapse-item > .ant-collapse-header {
	border-bottom: 1px solid #000;
}

.ant-menu-item > a {
	color: inherit;
	display: inherit;
}

.ant-pagination-item > a {
	font-family: "Graphik Compact", sans-serif;
	font-size: 14px;
	color: var(--color-main);
}

.ant-pagination-item-active, .ant-pagination-item-active:focus {
	background-color: var(--color-main);
}

.ant-pagination-item-active a, .ant-pagination-item-active:focus a {
	color: white;
}

.anticon {
	font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ant-modal-content {
	border-radius: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
	border-color: #001529;
	border-bottom: 0;
	top: 0;
	margin-top: 0;
}

.ant-modal-title {
	font-weight: 600;
	font-size: 24px;
}

.ant-layout-header {
	border-top: 1.5px solid #606060;
	background: #000000;
	padding: 0 32.8px 0 17.6px;
	height: 77px;
	line-height: 77px;
	-webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.4);
}

.ant-layout-header .ant-menu-dark, .ant-layout-header .ant-menu-dark .ant-menu-sub {
	background: black;
	line-height: 74.5px !important;
}

.ant-layout-header .avatar {
	font-size: 16px;
	color: white;
	margin-left: 16px;
	cursor: pointer;
	font-family: "Graphik Compact", sans-serif;
	word-wrap: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 200px;
}

.ant-layout-header .logo {
	margin-top: -1.5px;
	margin-right: 190.4px;
	height: 31px;
	width: 30px;
}

.ant-modal, .ant-modal-header, .ant-modal-footer, .ant-select, .ant-select-selection, .ant-select-dropdown,
.ant-btn, .ant-table, .ant-select-dropdown-menu-item, .ant-input, .ant-collapse-item, .ant-collapse-header,
.ant-collapse-item-active, .ant-collapse, .link-item, .ant-pagination-item, .ant-popover-content, .ant-popover,
.ant-popover-inner, .dict-item, .ant-pagination-item-link, .ant-dropdown-menu, .ant-dropdown, .ant-message,
.ant-message-notice-content {
	border-radius: 0 !important;
}

.ant-modal:hover, .ant-modal-header:hover, .ant-modal-footer:hover, .ant-select:hover, .ant-select-selection:hover, .ant-select-dropdown:hover,
  .ant-btn:hover, .ant-table:hover, .ant-select-dropdown-menu-item:hover, .ant-input:hover, .ant-collapse-item:hover, .ant-collapse-header:hover,
  .ant-collapse-item-active:hover, .ant-collapse:hover, .link-item:hover, .ant-pagination-item:hover, .ant-popover-content:hover, .ant-popover:hover,
  .ant-popover-inner:hover, .dict-item:hover, .ant-pagination-item-link:hover, .ant-dropdown-menu:hover, .ant-dropdown:hover, .ant-message:hover,
  .ant-message-notice-content:hover {
	border-radius: 0;
}

.ant-popover-inner-content {
	background-color: #f4f3f7;
}

.ant-popover-arrow {
	border-color: #f4f3f7 !important;
	right: 4px !important;
	box-shadow: none !important;
}

.ant-popover-inner {
	box-shadow: none;
}

.ant-input, .ant-form-item-required:before {
	font-family: "Graphik Compact", sans-serif !important;
	font-weight: normal;
}

.ant-message {
	z-index: 10000 !important;
}

.axisDefaultButton, .ant-modal-footer .ant-btn, .ant-pagination-item {
	font-family: "Graphik Compact", sans-serif;
	font-size: 14px;
	border: 1px solid var(--color-main) !important;
	color: var(--color-main);
}

.axisPrimaryButton, .ant-modal-footer .ant-btn-primary, .ant-btn-primary, .ant-btn-primary:focus {
	font-family: "Graphik Compact", sans-serif;
	font-size: 14px;
	border: 1px solid var(--color-main) !important;
	background-color: var(--color-main);
	color: white;
}

.axisPrimaryButton:hover, .ant-modal-footer .ant-btn-primary:hover, .ant-btn-primary:hover {
	background-color: white;
	color: var(--color-main-light);
}

.axisDefaultButton:hover, .ant-modal-footer .ant-btn:hover, .ant-pagination-item > a:hover {
	color: var(--color-main-light);
}

.ant-modal-mask {
	z-index: 9000 !important;
}

.ant-modal-wrap {
	z-index: 9000 !important;
}

.ant-select-dropdown {
	z-index: 9100 !important;
}

.ant-input, .ant-select-selection-selected-value {
	font-size: 17px;
}

.ant-dropdown {
	z-index: 9100 !important;
}

.ant-notification {
	margin-top: 70px;
	z-index: 100000 !important;
}

li.ant-pagination-options .ant-select-selection.ant-select-selection--single {
	min-width: 80px !important;
}

.ant-layout {
  background-color: inherit;
}
