.container {
  padding: 36px 75px 68px 62px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.actions {
  display: flex;
  margin-left: 20px;
  flex-shrink: 0;
}

.buttonSecondary {
  font-weight: bold;
  margin-right: 38px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  margin-top: 40px;
  color: var(--color-secondary-dark);
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.buttonPrimary {
  margin: 0 12px;
  padding: 8px 12px;
  font-size: 18px;
}

.buttonPrimary > span {
  font-size: 14px;
  min-width: 24px;
  min-height: 24px;
  line-height: 16px;
}

.buttonSecondary {
  font-weight: normal;
  margin-right: 40px;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 6px;
  margin-bottom: 50px;
}

.filterDescription {
  margin: 0;
}

.filterEdit {
  flex-shrink: 0;
  margin-left: 20px;
}

.titleSeparator {
  height: 1px;
  background-color: var(--color-gray);
  border: none;
}

.separator {
  margin: 30px 0;
  height: 1px;
  background-color: var(--color-light-gray);
  border: none;
}

.article {
  display: flex;
  align-items: center;
}

.input {
  flex-shrink: 0;
  margin: 0 30px 0 50px;
}

.end {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}
